<template>
    <div class="radar-chart"></div>
  </template>
  
  <script>
  import * as d3 from 'd3';
  
  export default {
    name: 'RadarChart',
    props: {
      data: Array,
    },
    mounted() {
      const maxWidth = 640;
      const width = Math.min(window.innerWidth, maxWidth);

      var id = ".radar-chart";

      var data = this.data;

      // Параметры графика
      var cfg = {
        size: width,
        scaleFactor: 0.62,
        levels: 10,	
        strokeWidth: 2,	
        dotRadius: 3,
        maxValue: 1,
        wrapWidth: 60,
        opacityArea: 0.2,
        opacityCircles: 0.1,
        color: "#26c2d0"
      };
      
      // При необходимости увеличиваем максимальное значение графика, чтобы поместились
      var maxValue = Math.max(cfg.maxValue, d3.max(data, function(i){return d3.max(i.map(function(o){return o.value;}))}));
      
      // Параметры осей
      var allAxis = data[0].map( function(i){return i.id} );	// Массив id характеристик
      var radius = cfg.size*cfg.scaleFactor/2; 
      var angleSlice = Math.PI * 2 / allAxis.length;
      
      //функция скейла до масштабов радиуса
      var rScale = d3.scaleLinear()
        .range([0, radius])
        .domain([0, maxValue]);

      //Удаляем SVG с таким же id если был создан ранее
      d3.select(id).select("svg").remove();

      //Инициализация SVG графика
      var svg = d3.select(id).append("svg")
        .attr("width",  cfg.size)
        .attr("height", cfg.size)
        .attr("class", "radar"+id);
      

      //Добавление g элемента		
      var g = svg.append("g")
        .attr("transform", "translate(" + (cfg.size/2) + "," + (cfg.size/2) + ")");

      //Обертка
      var axisGrid = g.append("g").attr("class", "axisWrapper");

      //Концентрические окружности
      axisGrid.selectAll(".levels")
        .data(d3.range(1,(cfg.levels+1)).reverse())
        .enter()
        .append("circle")
        .attr("class", "gridCircle")
        .attr("r", function(d){return radius/cfg.levels*d;})
        .style("stroke", "#3e3b44") // цвет контуров
        .style("fill", "#2a2b30"); // Задаём цвет заливки кругов

      //создания g элементов для осей
      var axis = axisGrid.selectAll(".axis")
        .data(allAxis)
        .enter()
        .append("g")
        .attr("class", "axis");

      //Спицы
      axis.append("line")
        .attr("x1", 0)
        .attr("y1", 0)
        .attr("x2", function(d, i){ return rScale(maxValue) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("y2", function(d, i){ return rScale(maxValue) * Math.sin(angleSlice*i - Math.PI/2); })
        .attr("class", "line")
        .style("stroke", "#3e3b44")
        .style("stroke-width", "1px");

      //кружки с иконками осей
      axis.append("circle")
        .attr("class", "label-circle")
        .attr("r", cfg.size/18)
        .attr("cx", function(d, i){ return rScale(maxValue*1.3) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("cy", function(d, i){ return rScale(maxValue*1.3) * Math.sin(angleSlice*i - Math.PI/2); })
        .style("fill", "#2a2b30");

      var iconSize = radius / 3;
      var iconScale = iconSize / 130; 
      var iconTranslate = iconSize / 2;

      var axisData = [
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" stroke="none"><path d="M410 971 c-112 -34 -190 -142 -190 -262 0 -51 31 -145 64 -198 59 -93 163 -186 294 -265 74 -45 70 -46 199 41 108 72 211 181 254 269 31 62 34 77 34 154 0 101 -19 146 -90 208 -88 77 -192 81 -286 9 l-43 -34 -43 33 c-63 48 -130 64 -193 45z m132 -77 c15 -8 42 -32 59 -54 18 -22 37 -40 42 -40 6 0 31 22 57 49 56 59 104 76 164 60 57 -16 93 -47 123 -106 22 -46 25 -62 20 -110 -12 -120 -108 -249 -261 -353 -42 -28 -83 -53 -90 -56 -38 -15 -255 161 -315 255 -39 60 -64 154 -55 206 21 132 147 205 256 149z"/><path d="M533 773 c-7 -2 -20 -31 -29 -64 l-17 -59 -67 0 c-53 0 -69 -3 -74 -16 -12 -32 13 -44 95 -44 56 0 82 4 93 15 14 14 16 11 26 -27 38 -145 51 -178 70 -178 24 0 31 15 60 122 20 79 40 107 40 58 0 -36 27 -50 100 -50 84 0 100 6 100 36 0 26 -15 32 -94 36 l-49 3 -21 77 c-12 42 -26 82 -32 88 -18 18 -43 0 -53 -39 -6 -20 -18 -66 -28 -104 -10 -39 -20 -62 -24 -54 -4 7 -17 51 -29 97 -26 103 -35 117 -67 103z"/></g></svg>`}, 
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)"><path d="M574 990 c-17 -4 -44 -14 -60 -23 -16 -9 -48 -21 -72 -27 -25 -7 -54 -24 -72 -43 -62 -67 -120 -138 -122 -150 -2 -22 -8 -141 -7 -177 0 -88 93 -157 199 -147 37 3 64 0 81 -9 13 -7 49 -13 80 -14 80 0 153 -35 207 -99 24 -28 51 -55 60 -59 25 -13 80 5 98 32 14 23 14 28 -7 76 l-22 51 25 27 c40 42 51 71 50 131 0 31 3 60 8 66 4 5 13 29 19 53 24 89 -21 160 -158 246 -54 34 -70 39 -124 40 -34 1 -71 7 -82 12 -31 17 -67 22 -101 14z m52 -87 c-4 -16 -10 -41 -13 -56 -3 -18 -15 -32 -31 -38 -82 -31 -59 -56 50 -55 68 1 83 4 86 17 2 9 -9 24 -23 33 -62 40 -3 112 85 103 34 -4 51 -12 70 -35 14 -16 42 -35 63 -42 45 -14 77 -59 77 -107 0 -39 -10 -41 -71 -13 -53 24 -97 26 -105 5 -9 -23 15 -45 47 -45 46 0 90 -46 96 -101 10 -91 -41 -138 -159 -147 -63 -4 -68 -3 -68 15 0 11 7 26 16 34 8 7 22 25 30 41 8 15 23 28 33 28 24 0 45 25 37 45 -7 18 -65 20 -89 2 -10 -6 -25 -28 -35 -47 -35 -70 -124 -100 -193 -65 -16 9 -40 34 -54 55 -22 36 -28 39 -46 29 -14 -7 -18 -16 -14 -27 22 -53 21 -55 -26 -49 -59 8 -89 42 -89 101 0 58 16 70 84 63 51 -5 76 3 76 26 0 15 -68 37 -115 37 -58 0 -60 20 -5 70 22 20 40 41 40 47 0 5 9 19 20 31 15 16 32 22 65 22 25 0 45 3 45 8 0 15 56 41 89 42 31 0 33 -2 27 -27z m274 -552 c15 -29 12 -51 -6 -51 -18 0 -53 38 -54 58 0 20 48 14 60 -7z"/><path d="M610 661 c-33 -33 -42 -87 -15 -97 20 -8 45 13 45 37 0 10 12 24 28 31 37 18 37 52 -1 56 -20 2 -35 -5 -57 -27z"/></g></svg>`}, 
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M603 999 c-30 -9 -62 -43 -76 -81 -5 -15 -24 -18 -134 -20 l-128 -3 -3 -114 c-2 -90 0 -117 12 -130 12 -13 16 -50 18 -166 l3 -150 352 -3 c345 -2 352 -2 363 18 7 12 10 73 9 150 -1 85 3 135 10 144 8 9 11 54 9 132 l-3 119 -126 3 -126 3 -16 34 c-28 59 -95 85 -164 64z m77 -49 c11 0 40 -29 40 -41 0 -5 -31 -9 -70 -9 -72 0 -82 6 -52 32 22 20 44 28 60 22 8 -2 17 -4 22 -4z m310 -184 l0 -83 -67 -31 c-56 -25 -193 -62 -232 -62 -5 0 -11 10 -13 23 -3 23 -47 39 -49 18 -1 -6 -2 -17 -3 -23 -3 -28 -146 -1 -258 49 l-58 26 0 83 0 84 340 0 340 0 0 -84z m-457 -214 c52 -10 93 -23 93 -30 1 -7 2 -17 3 -23 2 -20 46 -5 49 18 3 18 12 22 61 28 32 4 95 19 140 34 l81 27 0 -113 0 -113 -310 0 -310 0 0 115 0 115 50 -20 c28 -11 92 -28 143 -38z"/></g></svg>`},
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M490 976 l0 -45 -47 -3 -48 -3 0 -40 c0 -39 1 -40 40 -45 l40 -5 3 -202 2 -203 -32 0 c-49 0 -60 -11 -56 -56 3 -39 3 -39 51 -42 l47 -3 0 -40 0 -40 43 3 c41 3 42 4 45 41 3 34 6 37 33 37 27 0 29 -2 29 -40 l0 -40 40 0 c40 0 40 0 40 39 l0 38 52 7 c28 4 63 13 79 21 55 29 90 125 69 194 -11 38 -56 91 -77 91 -18 0 -17 4 17 40 77 82 32 227 -76 245 -62 10 -64 12 -64 55 l0 40 -40 0 -40 0 0 -45 c0 -43 -1 -45 -29 -45 -28 0 -30 3 -33 43 -3 42 -3 42 -45 45 l-43 3 0 -45z m291 -165 c26 -32 24 -67 -6 -96 -22 -23 -32 -25 -110 -25 l-85 0 0 68 c0 38 3 72 8 76 4 4 45 6 90 4 74 -3 86 -6 103 -27z m-36 -221 c73 -17 99 -87 50 -135 -22 -23 -32 -25 -120 -25 l-95 0 0 78 c0 43 3 82 7 85 9 10 111 8 158 -3z"/></g></svg>`},
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M614 1070 c-46 -18 -67 -77 -43 -121 8 -16 5 -26 -20 -54 -33 -38 -50 -99 -34 -124 8 -13 33 -17 119 -19 60 -2 120 0 133 3 22 6 23 10 19 56 -4 40 -12 57 -39 85 -26 26 -30 37 -21 46 23 23 14 77 -17 109 -30 29 -58 35 -97 19z m76 -61 c9 -16 8 -26 -5 -45 -17 -27 -27 -29 -56 -14 -19 10 -27 59 -12 73 15 16 63 6 73 -14z m28 -146 c12 -13 22 -33 22 -44 0 -17 -8 -19 -90 -19 -75 0 -90 3 -90 16 0 28 21 55 53 69 40 17 76 10 105 -22z"/><path d="M423 868 c-37 -40 -69 -100 -78 -151 -6 -33 -5 -37 14 -37 14 0 23 8 27 28 10 39 39 95 70 131 23 28 25 34 13 47 -13 12 -19 10 -46 -18z"/><path d="M829 884 c-10 -12 -7 -20 13 -42 29 -30 62 -92 72 -134 8 -34 46 -40 46 -8 0 47 -73 181 -106 194 -7 3 -18 -2 -25 -10z"/><path d="M335 594 c-27 -29 -32 -72 -14 -105 8 -17 6 -24 -15 -40 -28 -22 -50 -81 -44 -119 l3 -25 135 0 c123 0 135 2 138 18 7 36 -9 88 -38 120 -20 24 -26 37 -19 48 17 27 10 75 -16 103 -34 37 -96 37 -130 0z m100 -38 c19 -29 10 -54 -21 -62 -30 -8 -54 11 -54 40 0 47 49 62 75 22z m8 -128 c30 -16 46 -39 47 -65 0 -22 -3 -23 -90 -23 -88 0 -90 0 -90 24 0 30 52 76 86 76 14 0 35 -5 47 -12z"/><path d="M842 600 c-29 -27 -38 -66 -24 -103 10 -26 9 -32 -13 -49 -27 -21 -49 -80 -43 -118 l3 -25 135 0 c123 0 135 2 138 18 7 36 -9 88 -38 120 -20 24 -26 37 -19 48 17 28 10 79 -16 104 -31 32 -91 34 -123 5z m93 -44 c13 -19 14 -29 5 -45 -20 -38 -80 -24 -80 18 0 52 48 69 75 27z m14 -131 c27 -14 56 -67 44 -79 -4 -3 -46 -6 -95 -6 -82 0 -88 1 -88 21 0 29 53 79 85 79 14 0 39 -7 54 -15z"/><path d="M533 487 c-3 -5 2 -16 11 -25 12 -12 36 -16 93 -17 94 0 133 9 133 33 0 15 -13 17 -116 17 -64 0 -118 -4 -121 -8z"/></g></svg>`}, 
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M420 981 c-81 -25 -137 -79 -169 -166 -27 -72 -26 -107 3 -194 28 -84 91 -174 177 -257 64 -60 178 -145 211 -157 27 -10 162 86 258 183 109 109 159 202 167 314 5 68 3 80 -24 139 -34 70 -73 107 -142 132 -77 27 -142 15 -211 -38 l-43 -33 -31 28 c-51 47 -134 67 -196 49z m129 -60 c21 -11 51 -35 67 -53 31 -36 81 -125 74 -131 -3 -2 -29 -20 -57 -41 -29 -21 -53 -44 -53 -51 0 -8 20 -31 45 -52 25 -20 45 -42 45 -47 0 -6 -16 -26 -35 -46 l-35 -36 16 -48 c9 -26 23 -50 31 -53 25 -10 33 18 17 56 -14 35 -14 35 26 76 22 22 40 47 40 54 0 7 -21 32 -46 55 l-47 42 41 28 c79 54 80 57 37 128 l-37 63 37 32 c92 79 228 46 287 -71 42 -81 21 -205 -52 -312 -46 -66 -148 -164 -230 -220 l-68 -46 -69 48 c-112 80 -215 191 -260 280 -33 68 -38 87 -38 149 0 62 4 78 30 120 53 86 152 118 234 76z"/></g></svg>`}, 
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M593 1055 c-47 -20 -75 -70 -70 -123 3 -24 12 -51 21 -61 23 -25 21 -27 -30 -40 -67 -17 -87 -43 -130 -175 -36 -110 -38 -118 -23 -144 12 -22 22 -27 52 -27 20 0 37 -1 37 -3 0 -1 -25 -18 -55 -37 -68 -44 -89 -74 -80 -115 13 -58 22 -60 323 -60 299 0 325 4 338 57 10 37 -13 72 -68 108 -26 17 -55 35 -65 42 -15 10 -12 11 20 6 45 -7 77 16 77 57 0 42 -77 243 -101 263 -12 9 -41 22 -65 28 -49 13 -51 15 -28 40 9 10 18 37 21 61 5 54 -23 103 -72 124 -42 17 -61 17 -102 -1z m115 -61 c25 -28 28 -54 10 -87 -30 -57 -92 -64 -132 -16 -30 35 -33 65 -10 97 33 48 92 50 132 6z m50 -199 c62 -19 68 -28 113 -157 25 -74 30 -101 22 -109 -9 -9 -41 -2 -129 29 -131 45 -111 46 -277 -14 -106 -38 -109 -33 -62 105 21 61 44 116 51 122 45 37 194 50 282 24z m-46 -260 c29 -10 49 -25 56 -40 6 -13 44 -46 83 -72 40 -26 77 -56 83 -65 26 -42 -39 -57 -236 -58 -118 0 -150 3 -162 15 -35 35 -2 52 147 76 79 12 98 22 79 41 -12 12 -192 -13 -235 -33 -29 -13 -50 -54 -42 -80 6 -16 1 -19 -34 -19 -50 0 -86 14 -95 36 -9 23 6 38 86 90 38 24 73 55 80 69 11 25 71 53 119 54 14 1 46 -6 71 -14z"/><path d="M605 746 c-8 -19 -15 -42 -15 -51 0 -9 -23 -25 -55 -40 -33 -14 -55 -30 -55 -39 0 -21 12 -20 81 5 54 20 58 23 68 65 6 24 13 44 16 44 3 0 10 -20 16 -44 10 -42 14 -45 68 -65 71 -26 81 -26 81 -3 0 11 -17 24 -51 37 -47 18 -52 24 -68 70 -13 42 -20 50 -44 53 -23 3 -30 -2 -42 -32z"/></g></svg>`},
        {icon: `<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="130.000000px" height="130.000000px" viewBox="0 0 130.000000 130.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,130.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none"><path d="M397 984 c-4 -4 -7 -71 -7 -149 l0 -143 -45 6 c-54 6 -90 -15 -110 -62 -45 -107 96 -195 176 -110 23 24 24 33 29 196 l5 170 43 -61 c45 -64 53 -103 27 -137 -21 -27 -19 -54 4 -54 13 0 27 15 41 46 30 60 20 91 -63 209 -59 84 -83 106 -100 89z m-29 -340 c44 -31 20 -104 -35 -104 -57 0 -82 61 -43 100 24 24 48 25 78 4z"/><path d="M808 873 l-138 -56 0 -193 c0 -107 -3 -194 -6 -194 -3 0 -15 5 -26 11 -29 16 -91 -4 -117 -38 -64 -81 26 -200 121 -160 61 25 63 32 68 236 l5 185 113 42 112 41 0 -99 c0 -94 -1 -98 -19 -88 -70 37 -161 -38 -145 -120 14 -70 111 -104 167 -59 42 33 48 74 45 319 -3 217 -4 225 -23 227 -11 1 -82 -24 -157 -54z m132 -41 l0 -38 -107 -39 c-58 -22 -110 -41 -115 -43 -4 -2 -8 14 -8 35 l0 38 103 42 c56 23 108 42 115 42 7 1 12 -13 12 -37z m-35 -314 c30 -14 42 -50 27 -83 -21 -45 -92 -41 -107 6 -14 42 5 74 52 88 1 1 14 -4 28 -11z m-260 -132 c25 -18 25 -74 0 -92 -40 -30 -105 -1 -105 46 0 47 65 76 105 46z"/></g></svg>`}
      ];

      axisData.forEach((axisDatum, i) => {
        var g = axis.append("g")
        .attr("class", "icon")
        .attr("id", "icon-" + i) // Присваиваем уникальный идентификатор
        .html(axisDatum.icon) // Добавляем SVG код
        .attr("transform", function() {
          var x = rScale(maxValue * 1.3) * Math.cos(angleSlice * i - Math.PI/2) - iconTranslate;
          var y = rScale(maxValue * 1.3) * Math.sin(angleSlice * i - Math.PI/2) - iconTranslate;
          return `translate(${x},${y}) scale(${iconScale})`; // Позиционирование и масштабирование иконки
        });
        var fillColor = data[0][i].value === 0 ? "#555555" : "#26c2d0"; // Серый для 0, исходный цвет для остальных

        // Добавляем иконку с заданным цветом
        g.selectAll("path")
          .style("fill", fillColor);
      });

      ///////// ЗНАЧЕНИЯ НА ДИАГРАММЕ: /////////
      
      //функции генерации пути радарной линии
      var radarLine = d3.lineRadial()
        .curve(d3.curveCardinalClosed)
        .radius(function(d) { return rScale(d.value); })
        .angle(function(d,i) {	return i*angleSlice; });
            
      //создание g элементов для лопастей значений
      var blobWrapper = g.selectAll(".radarWrapper")
        .data(data)
        .enter().append("g")
        .attr("class", "radarWrapper");
          
      //заливка контура	
      blobWrapper
        .append("path")
        .attr("class", "radarArea")
        .attr("d", function(d) { return radarLine(d); })
        .style("fill", cfg.color)
        .style("fill-opacity", cfg.opacityArea)
        .on('mouseover', function (){
          //Dim all blobs
          d3.selectAll(".radarArea")
            .transition().duration(200)
            .style("fill-opacity", 0.1); 
          //Bring back the hovered over blob
          d3.select(this)
            .transition().duration(200)
            .style("fill-opacity", 0.7);	
        })
        .on('mouseout', function(){
          //Bring back all blobs
          d3.selectAll(".radarArea")
            .transition().duration(200)
            .style("fill-opacity", cfg.opacityArea);
        });
        
      // Контурная линии
      blobWrapper.append("path")
        .attr("class", "radarStroke")
        .attr("d", function(d) { return radarLine(d); })
        .style("stroke-width", cfg.strokeWidth + "px")
        .style("stroke", cfg.color)
        .style("fill", "none")
      
      // Точки на линии
      blobWrapper.selectAll(".radarCircle")
        .data(function(d) { return d; })
        .enter().append("circle")
        .attr("class", "radarCircle")
        .attr("r", cfg.dotRadius)
        .attr("cx", function(d,i){ return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
        .attr("cy", function(d,i){ return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
        .style("fill", cfg.color)
    },
  };
  </script>
  