<template>
  <div id="app">
    <radar-chart v-if="balanceData?.length" :data="balanceData"></radar-chart>
  </div>
</template>


<script>
/* global Telegram */
import { ref, onMounted } from 'vue';
import RadarChart from '@/components/RadarChart.vue';

export default {
  name: 'App',
  components: {
    RadarChart
  },
  setup() {
    const balanceData = ref([]);

    const loadUserBalance = async (tgId) => {
      try {
        const response = await fetch(`https://api.balancegu.ru/users/balance?tg_id=${tgId}`);
        if (!response.ok) {
          throw new Error('Ошибка запроса: ' + response.statusText);
        }
        const data = await response.json();
        // Преобразование полученных данных в требуемый формат
        const formattedData = Object.keys(data).map(key => ({
          id: parseInt(key),
          value: parseFloat(data[key])
        }));
        console.log(formattedData);
        balanceData.value = [formattedData];
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      }
    };

    onMounted(async () => {
      if (window.Telegram?.WebApp) {
        Telegram.WebApp.ready();
        Telegram.WebApp.setHeaderColor('#3e3b44');
        const tgId = Telegram.WebApp.initDataUnsafe.user.id; //309576997;//
        await loadUserBalance(tgId);
        console.log(balanceData.value);
      }
    });

    return {
      balanceData
    };
  }
};
</script>

<style lang="scss">
  body {
    fill: #242424;
    text-align: center;
    background-color: #3e3b44;
    margin: 0;
    display: flex;
    justify-content: center;
  }

  .radar-chart {
    margin: 0;
  }
</style>





